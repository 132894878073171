import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import DetailsModal from './../ReadDetails/DetailsModal';
import { CardActionArea } from '@mui/material';

const BottomNews = ({news}) => {
    const [details,setDetails] = useState({});
     const [openBooking, setOpenBooking] = React.useState(false);
     const handleBookingOpen = () => setOpenBooking(true);
     const handleBookingClose = () => setOpenBooking(false);
    const readDetails = (id) =>{       
        const found = news.find(data =>data._id === id)
        setDetails(found)
        handleBookingOpen()
    } 

    return (
        <Box sx={{px:{xs:1,sm:3}}}> 
    <Grid container spacing={2} sx={{py:5}}>
        <Grid item sx={{borderRight:{xs:"none",sm:"none",md:"2px solid #e0e0e0"},pr:{xs:0,sm:0,md:2}}} xs={12} sm={12} md={8}>
            {
                news?.slice(17,).map(singleNews=><Card key={singleNews._id} onClick={()=>readDetails(singleNews._id)} sx={{ display: 'flex',my:3,p:1,cursor:"pointer" }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 250, height: 200 }}
                        image={singleNews.img}
                        alt="Live from space album cover"
                    />
                    <Box>
                    <CardContent sx={{ flex: '1 0 auto',padding:"5px",width:"100%" }}>
                    <Typography sx={{textAlign:"justify",fontWeight:"bolder",display:{xs:"none",sm:"none",md:"block"}}} component="div" variant="body1">
                                {singleNews?.heading}
                        </Typography>
                        <Typography sx={{textAlign:"justify",fontWeight:"bolder",display:{xs:"none",sm:"block",md:"none"}}} component="div" variant="body1">
                                {singleNews?.heading?.slice(0,70)}...
                        </Typography>
                        <Typography sx={{textAlign:"justify",fontWeight:"bolder",display:{xs:"block",sm:"none",md:"none"}}} component="div" variant="body1">
                                {singleNews?.heading?.slice(0,20)}...
                        </Typography>
                        <Typography sx={{textAlign:"justify",fontWeight:"bolder",padding:"2px 0px",color:"#cecece"}} variant="caption" display="block" gutterBottom>
                                {singleNews?.time}
                        </Typography>
                        <Typography sx={{textAlign:"justify",display:{xs:"none",sm:"none",md:"block"}}} color="text.secondary" variant="body1">
                            {singleNews?.details?.slice(0,180)} <span style={{color:"#587DEF"}}>read more</span>...
                        </Typography>
                        <Typography sx={{textAlign:"justify",display:{xs:"none",sm:"block",md:"none"}}} color="text.secondary" variant="body1">
                            {singleNews?.details?.slice(0,120)} <span style={{color:"#587DEF"}}>read more</span>...
                        </Typography>
                        <Typography sx={{textAlign:"justify",display:{xs:"block",sm:"none",md:"none"}}} color="text.secondary" variant="body1">
                            {singleNews?.details?.slice(0,30)} <span style={{color:"#587DEF"}}>read more</span>...
                        </Typography>
                    </CardContent>
                    </Box>
                </Card>)
            }
            
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
            {
                news.slice(11,13).map(singleNews=>
                     <Card onClick={()=>readDetails(singleNews._id)} sx={{ maxWidth: "100%",my:4}}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="250"
                            image={singleNews.img}
                            alt="green iguana"
                            />
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            {singleNews?.heading}
                            </Typography>
                            <Typography sx={{textAlign:"justify"}} variant="body2" color="text.secondary">
                            {singleNews?.details?.slice(0,200)}  <span style={{color:"#587DEF"}}>read more</span>...
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>)}
        </Grid>
      </Grid>
      <DetailsModal
        news={details}         
        openBooking={openBooking}
        handleBookingClose={handleBookingClose}
        ></DetailsModal>
      </Box>
    );
};

export default BottomNews;