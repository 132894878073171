import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { makeStyles } from '@mui/styles';
import Link from '@mui/material/Link';


const useStyle = makeStyles({
    icon:{
        fontSize: "2rem",
        padding: "5px",
        border: "2px solid red",
        margin: "5px",
        borderRadius: "50%",
        cursor:"pointer",
        "&:hover":{
            color:"#fff",
            background:"red",
            transition:".4s"
        }
        }
})

const Footer = () => {
    const classes = useStyle();
    return (
        <Box sx={{borderTop:"2px solid black",mt:10}}>
              <Typography sx={{textAlign:'center',pt:5}} variant="subtitle1" gutterBottom component="div">

                <span><Link href="https://www.facebook.com/profile.php?id=100088866501145&mibextid=LQQJ4d" target="_blank" style={{textDecoration:"none",color:"black"}}><FacebookIcon className={classes.icon}></FacebookIcon></Link></span>

                <span><Link href="https://www.linkedin.com/company/investment-insights-360/" target="_blank" style={{textDecoration:"none",color:"black"}}><LinkedInIcon className={classes.icon}></LinkedInIcon></Link></span>

                <span><Link href="https://twitter.com/investins360/status/1609434100787720192?s=46&t=Hp4OZ0aMPmmGsfqAaNSZlg" target="_blank" style={{textDecoration:"none",color:"black"}}><TwitterIcon className={classes.icon}></TwitterIcon></Link></span>

                <span><Link href="https://www.instagram.com/investmentinsights360/?igshid=Zjc2ZTc4Nzk%3D" target="_blank" style={{textDecoration:"none",color:"black"}}><InstagramIcon className={classes.icon}></InstagramIcon></Link></span>

                <span><Link href="https://youtube.com/@investmentinsights360" target="_blank" style={{textDecoration:"none",color:"black"}}><YouTubeIcon className={classes.icon}></YouTubeIcon></Link></span>
              </Typography>
             
              <Typography sx={{fontWeight:"bolder",pt:1}} variant="button" display="block" gutterBottom>
              © Copyright 2023, All rights reserved. Design by Mohammad Hanif email:<span style={{textTransform:"lowercase"}}> mdhanif115825@gmail.com</span>
            </Typography>
        </Box>
    );
};

export default Footer;