import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContactFormHeader from './ContactFormHeader';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Link from '@mui/material/Link';

const useStyle = makeStyles({
    contactArea:{
        backgroundColor:"#000000"
    },
    contactAreaSmall:{
        backgroundColor:'#000000',
        paddingStart:"20px"
    },
    contact:{
        textAlign:"start"
    },
    icon:{
        color:"red",
        padding:"3px",
        border:"1px solid",
        borderRadius:'20%',
        marginRight:'10px',
        cursor:"pointer",
        "&:hover":{
            color:"#fff",
            background:"#e40000",
            transition:".4s"
        }
    }
})

const ContactInfo = () => {
    const classes = useStyle();
    return (
        <Box sx={{mb:{xs:10,sm:10}}}>
            <Box sx={{display:{xs:"none",sm:"block"}}} className={classes.contactArea}>
            <Grid container spacing={2}>
                <Grid  item xs={12} md={5} >
                    <Box sx={{p:{xs:2,sm:8}}} className={classes.contact}>
                    <Typography sx={{fontSize:"1.2rem",color:"#fff"}} variant="button" display="block" gutterBottom>
                        Location
                    </Typography>
                    <Typography sx={{color:"#fff"}} variant="body1" gutterBottom>
                    Road 12, Middle Badda <br />
                    Dhaka 1212, Bangladesh
                    </Typography>
                    <Typography sx={{color:"#fff"}} variant="body1" gutterBottom>
                    Email: meng.insights@gmail.com
                    </Typography>
                        <Box sx={{mt:{xs:5,sm:20}}}>
                        <Typography sx={{fontSize:"1.1rem",color:"#fff",pb:1,fontWeight:"bolder"}} variant="button" display="block" gutterBottom>
                            Follow us
                        </Typography>
                        <Box sx={{display:"flex"}}>
                                <Box>
                                <Link target="_blank" href="https://www.facebook.com/profile.php?id=100088866501145&mibextid=LQQJ4d" >
                                    <span>
                                    <FacebookIcon className={classes.icon}/>
                                    </span>
                                </Link>
                                </Box>
                                <Box>
                                <Link target="_blank" href="https://www.linkedin.com/company/investment-insights-360/" >
                                <span>
                                    <LinkedInIcon className={classes.icon}/>
                                </span>
                                </Link>
                                </Box>
                                
                                <Box>
                                <Link target="_blank" href="https://twitter.com/investins360/status/1609434100787720192?s=46&t=Hp4OZ0aMPmmGsfqAaNSZlg" >
                                <span>
                                    <TwitterIcon className={classes.icon}/>
                                    </span>
                                </Link>
                                </Box>
                                
                                <Box>
                                    <Link href="#">
                                        <span>
                                        <EmailIcon className={classes.icon}/>
                                        </span>
                                    </Link>
                                </Box>
                                
                                <Box>
                                    <Link target="_blank" href="https://www.instagram.com/investmentinsights360/?igshid=Zjc2ZTc4Nzk%3D">
                                    <span>
                                    <InstagramIcon className={classes.icon}/>
                                    </span>
                                    </Link>
                                    
                                </Box>

                                <Box>
                                    <Link target="_blank" href="https://youtube.com/@investmentinsights360">
                                    <span>
                                    <YouTubeIcon className={classes.icon}/>
                                    </span>
                                    </Link>
                                    
                                </Box>
                                </Box> 
                           
                            <Typography sx={{color:"#fff",pt:1,fontWeight:"bolder"}} variant="subtitle2" gutterBottom component="div">
                            @2023 Privacy Policy @ Mohammad Hanif
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid  item xs={12} md={7}>
                    <ContactFormHeader></ContactFormHeader>
                </Grid>
            </Grid>
            </Box>

            <Box sx={{display:{xs:"block",sm:"none"}}}>
            <Grid  className={classes.contactAreaSmall} container>
                <Grid  item xs={12}>
                    <Box className={classes.contact}>
                    <Typography sx={{fontSize:"1.2rem",color:"#fff",pt:5}} variant="button" display="block" gutterBottom>
                        Location
                    </Typography>
                    <Typography sx={{color:"#fff"}} variant="body1" gutterBottom>
                    Road 12, Middle Badda <br />
                    Dhaka 1212, Bangladesh
                    </Typography>
                    <Typography sx={{color:"#fff"}} variant="body1" gutterBottom>
                    Email: meng.insights@gmail.com
                    </Typography>
                        <Box sx={{mt:{xs:2}}}>
                        <Typography sx={{fontSize:"1.1rem",color:"#fff",pb:1}} variant="button" display="block" gutterBottom>
                            Follow us
                        </Typography>
                            <Box sx={{display:"flex"}}>

                            <Box>
                                <Link target="_blank" href="https://www.facebook.com/profile.php?id=100088866501145&mibextid=LQQJ4d" >
                                <span>
                                    <FacebookIcon className={classes.icon}/>
                                </span>
                                </Link>
                            </Box>

                            <Box>
                                <Link target="_blank" href="https://www.linkedin.com/company/investment-insights-360/" >
                                <span>
                                    <LinkedInIcon className={classes.icon}/>
                                </span>
                                </Link>
                            </Box>
                                

                            <Box>
                                <Link target="_blank" href="https://twitter.com/investins360/status/1609434100787720192?s=46&t=Hp4OZ0aMPmmGsfqAaNSZlg" >
                                <span>
                                    <TwitterIcon className={classes.icon}/>
                                    </span>
                                </Link>
                                </Box>
                                
                                <Box>
                                    <Link href="#">
                                        <span>
                                        <EmailIcon className={classes.icon}/>
                                        </span>
                                    </Link>
                                </Box>
                                
                                <Box>
                                    <Link target="_blank" href="https://www.instagram.com/investmentinsights360/?igshid=Zjc2ZTc4Nzk%3D">
                                    <span>
                                    <InstagramIcon className={classes.icon}/>
                                    </span>
                                    </Link>
                                    
                                </Box>

                                <Box>
                                    <Link target="_blank" href="https://youtube.com/@investmentinsights360">
                                    <span>
                                    <YouTubeIcon className={classes.icon}/>
                                    </span>
                                    </Link>
                                    
                                </Box>
                                
                            </Box>
                            <Typography sx={{color:"#fff",pt:1}} variant="subtitle2" gutterBottom component="div">
                            @2021 Privacy Policy
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid  item xs={12}>
                    <ContactFormHeader></ContactFormHeader>
                </Grid>
            </Grid>
            </Box>
        </Box>
    );
};

export default ContactInfo;