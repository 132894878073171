import './App.css';
import Home from './Pages/Home/Home/Home';
import Login from './Pages/Login/Login';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import AuthProvider from './Pages/Sheared/Firebase/AuthProvider';
import Reagister from './Pages/Reagister/Reagister';
import BdNews from './Pages/BangladeshNews/BdNews';
import Sports from './Pages/Sports/Sports';
import Business from './Pages/Business/Business';
import Contact from './Pages/Contact/Contact';
import Deshboard from './Pages/Deshboard/DeshBoard/Deshboard';
import DeshSport from './Pages/Deshboard/DeshSport/DeshSport';
import DeshBangladesh from './Pages/Deshboard/DeshBangladesh/DeshBangladesh';
import DeshBusiness from './Pages/Deshboard/DeshBusiness/DeshBusiness';
import PrivateRoute from './Pages/Sheared/Navbar/PrivateRoute/PrivateRoute';



function App() {
  return (
    <div className='App'>
     <AuthProvider> 
      <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/reagister" element={<Reagister />} />
              <Route path="/bdnews" element={<BdNews />} />
              <Route path="/sports" element={<Sports />} />
              <Route path="/business" element={<Business />} />
              <Route path="/contact" element={<Contact />} />
               <Route path="/deshboard" element={ <PrivateRoute/>}>
                  <Route path="/deshboard/*" element={ <Deshboard/>}>  
                    <Route path="*" element={<DeshBangladesh/>}/>
                    <Route path="sport" element={<DeshSport/>}/>
                    <Route path="business" element={<DeshBusiness/>}/>
                  </Route> 
              </Route>           
            </Routes>
        </BrowserRouter>
     </AuthProvider>
     
      </div>
  );
}

export default App;

