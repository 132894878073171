import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from './../../Firebase/useAuth';

const PrivateRoute = () => {
    const {admin} = useAuth();
    return (
        <div>
            {
                admin? <Outlet/> : <Navigate to="/home" />
            }
        </div>
    );
};

export default PrivateRoute;